import {
  Badge,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  Square,
  Stack,
  Tag,
  Text,
  Wrap,
  WrapItem,
  Link,
  LinkOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import * as Router from "react-router-dom";
import Slider from "react-slick";
import { StarIcon } from "@chakra-ui/icons";
import axios from "axios";

function Highlight() {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    axios
      .get(`/content/items/news?sort=%7Bdate%3A+-1%7D&limit=6`)
      .then((response) => {
        setNewsData(response.data);
      });
  }, []);

  function badgeColor(type) {
    let color;
    switch (type) {
      case "info":
        color = "yellow";
        break;
      case "event":
        color = "pink";
        break;
      case "update":
        color = "blue";
        break;
      default:
        color = "grey";
    }
    return color;
  }

  const HighlightItem = ({ image, title, type, date }) => {
    return (
      <>
        <Box overflow="hidden" borderRadius={10} align={"center"}>
          <Image
            src={image}
            overflow="hidden"
            maxW={350}
            h={250}
            objectFit="cover"
            borderRadius={10}
            transition="transform 0.5s ease-in-out"
            _hover={{
              transform: "scale(1.1) rotate(-3deg)",
            }}
          />
        </Box>
        <Box paddingTop={5} maxW={350}>
          <Badge
            w={"auto"}
            textTransform={"lowercase"}
            colorScheme={badgeColor(type)}
          >
            {type}
          </Badge>
          <Heading
            fontSize={"md"}
            maxW={300}
            color="rgb(205,207,248)"
            textAlign={"left"}
            textOverflow="ellipsis"
            overflow={"hidden"}
            whiteSpace={"nowrap"}
            overflowWrap={"break-word"}
          >
            {title}
          </Heading>
        </Box>
        <Stack direction={"row"} align={"center"}>
          <Text fontSize={"sm"} color={"gray.300"}>
            {date}
          </Text>
        </Stack>
      </>
    );
  };

  return (
    <Flex
      bg="rgba(25,28,52,1)"
      flexDirection={"column"}
      alignItems="center"
      zIndex={999}
      p="30"
      paddingTop={100}
      paddingBottom={100}
    >
      <Heading
        size={"2xl"}
        color="rgb(205,207,248)"
        fontStyle={"inherit"}
        zIndex={999}
      >
        WHAT&apos;S NEW
      </Heading>
      <Wrap
        templateColumns="repeat(3, 3fr)"
        spacing={2}
        zIndex={999}
        marginTop={50}
        maxW={1300}
        justify={"center"}
      >
        {newsData.map((data) => {
          const link = `/news/${data._id}`;
          return (
            <WrapItem
              key={data._id}
              maxW="sm"
              overflow="hidden"
              p={2}
              flexDirection="column"
            >
              <Link
                href={link}
                onClick={(e) => (data.content === "" ? e.preventDefault() : {})}
              >
                <HighlightItem
                  title={data.title}
                  image={`https://api.eco-iris.com/storage/uploads/${data.image.path}`}
                  date={data.date}
                  type={data.type}
                />
              </Link>
            </WrapItem>
          );
        })}
      </Wrap>
      <Link href="/news">
        <Button marginTop={10} size="lg">
          VIEW ALL
        </Button>
      </Link>
    </Flex>
  );
}

export default Highlight;
