import {
  Box,
  Flex,
  Heading,
  Image,
  useColorModeValue,
  Wrap,
} from "@chakra-ui/react";
import axios from "axios";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";

function GuideDetail() {
  const [isLoading, setIsLoading] = useState(false);
  const [guideData, setGuideData] = useState({});
  const { guideId } = useParams();

  useEffect(() => {
    setIsLoading(true);
    axios.get(`/content/item/guide/${guideId}`).then((response) => {
      setGuideData(response.data);
      setIsLoading(false);
      console.log(response);
    });
  }, []);

  const { title, image, content } = guideData;

  return (
    <Wrap
      w={"full"}
      align={"center"}
      marginTop={50}
      direction={"column"}
      minH="calc(100vh - 400px)"
      p={10}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Box
          role={"group"}
          p={6}
          maxW={"1230px"}
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          boxShadow={"2xl"}
          rounded={"lg"}
          pos={"relative"}
          zIndex={1}
        >
          <Box
            rounded={"lg"}
            pos={"relative"}
            _after={{
              transition: "all .3s ease",
              content: '""',
              w: "full",
              h: "full",
              pos: "absolute",
              top: 5,
              left: 0,
              backgroundImage: `url(${`https://api.eco-iris.com/storage/uploads/${image?.path}`})`,
              backgroundSize: "cover",
              filter: "blur(15px)",
              zIndex: -1,
              filter: "blur(20px)",
            }}
          >
            <Flex justify={"center"}>
              <Image
                rounded={"lg"}
                w={"auto"}
                h={350}
                objectFit={"cover"}
                src={`https://api.eco-iris.com/storage/uploads/${image?.path}`}
              />
            </Flex>
          </Box>
          <Heading mt={50} textAlign={"center"} textTransform={"uppercase"}>
            {title}
          </Heading>
          <Flex pt={50} direction="column" className="content">
            {content ? parse(content) : null}
          </Flex>
        </Box>
      )}
    </Wrap>
  );
}

export default GuideDetail;
