import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import styled from "styled-components";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Download from "./pages/Download";
import News from "./pages/News";
import NotFound from "./pages/NotFound";
import Opening from "./pages/Opening";
import axios from "axios";
import NewsDetail from "./pages/NewsDetail";
import GameGuide from "./pages/GameGuide";
import GuideDetail from "./pages/GuideDetail";

// function App() {
//   return (
//     <>
//       <Navbar />
//       <Routes>
//         {/* <Route path="/news/:newsId">
//             <Detail />
//           </Route>
//           <Route exact path="/ticket">
//             <Ticket />
//           </Route>
//           <Route path="/ticket/:ticketId">
//             <TicketDetail />
//           </Route>
//           <Route exact path="/download">
//             <Download />
//           </Route>
//           <Route exact path="/feature">
//             <Feature />
//           </Route>
//           <Route exact path="/rules">
//             <Rules />
//           </Route>
//           <Route path="/feature/:featureId">
//             <FeatureDetail />
//           </Route>
//           <Route exact path="/news">
//             <NewsTabs />
//           </Route>
//           <Route exact path="/newsall">
//             <NewsAll />
//           </Route>
//           <Route exact path="/login">
//             <Login />
//           </Route>
//           <Route exact path="/register">
//             <Register />
//           </Route> */}
//         <Route path="/" element={<Home />} />
//         <Footer />
//       </Routes>
//     </>
//   );
// }
const FooterContainer = styled.div`
  bottom: 0;
`;

// {
//   newsData.content ? (
//     <div dangerouslySetInnerHTML={{ __html: newsData.content }} />
//   ) : null;
// }

function App() {
  axios.defaults.baseURL = "https://api.eco-iris.com/api";

  return (
    <>
      <Navbar />
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/download">
            <Download />
          </Route>
          <Route exact path="/news/:newsId">
            <NewsDetail />
          </Route>
          <Route exact path="/news">
            <News />
          </Route>
          <Route exact path="/guide">
            <GameGuide />
          </Route>
          <Route exact path="/guide/:guideId">
            <GuideDetail />
          </Route>
        </Switch>
      </Router>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </>
  );
}

export default App;
