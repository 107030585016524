import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useColorModeValue,
  Wrap,
} from "@chakra-ui/react";
import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";

function badgeColor(type) {
  let color;
  switch (type) {
    case "info":
      color = "yellow";
      break;
    case "event":
      color = "pink";
      break;
    case "update":
      color = "blue";
      break;
    default:
      color = "grey";
  }
  return color;
}

function NewsDetail() {
  const [isLoading, setIsLoading] = useState(false);
  const [newsData, setNewsData] = useState({});
  const { newsId } = useParams();

  useEffect(() => {
    setIsLoading(true);
    axios.get(`/content/item/news/${newsId}`).then((response) => {
      setNewsData(response.data);
      setIsLoading(false);
      console.log(response);
    });
  }, []);

  const { title, image, type, date, content } = newsData;

  return (
    <Wrap
      w={"full"}
      align={"center"}
      marginTop={50}
      direction={"column"}
      minH="calc(100vh - 400px)"
      p={10}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Box
          role={"group"}
          p={6}
          maxW={"1230px"}
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          boxShadow={"2xl"}
          rounded={"lg"}
          pos={"relative"}
          zIndex={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            rounded={"lg"}
            pos={"relative"}
            _after={{
              transition: "all .3s ease",
              content: '""',
              w: "full",
              h: "full",
              pos: "absolute",
              top: 5,
              left: 0,
              backgroundImage: `url(${`https://api.eco-iris.com/storage/uploads/${image?.path}`})`,
              backgroundSize: "cover",
              filter: "blur(15px)",
              zIndex: -1,
              filter: "blur(20px)",
            }}
          >
            <Flex justify={"center"}>
              <Image
                rounded={"lg"}
                w={"auto"}
                h={350}
                objectFit={"cover"}
                src={`https://api.eco-iris.com/storage/uploads/${image?.path}`}
              />
            </Flex>
          </Box>
          <Flex>
            <Heading
              size={"sm"}
              mt={50}
              mr={1}
              alignSelf={"center"}
              textTransform={"uppercase"}
              bg={badgeColor(type)}
              color={"white"}
              p={1}
              borderRadius={5}
            >
              {type}
            </Heading>
            <Heading size={"lg"} mt={50} alignSelf={"center"}>
              {title}
            </Heading>
          </Flex>
          <Text align={"start"}>{`Published on ${moment(date).format(
            "ddd DD MMM yyyy"
          )}`}</Text>
          <hr />
          <Flex pt={50} direction="column" className="content">
            {content ? parse(content) : null}
          </Flex>
        </Box>
      )}
    </Wrap>
  );
}

export default NewsDetail;
