import {
  AspectRatio,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Highlight,
  Image,
  Link,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import Lottie from "lottie-react";
import React from "react";
import { FaDiscord } from "react-icons/fa";
import scrollDown from "../resources/home/9284-scroll-down-arrows.json";
import vid from "../resources/video/irisBG.mp4";
import LOGO from "../resources/logo_white.png";

const CustomButton = ({ children, icon }) => (
  <Button
    color={"rgba(255,255,255,1)"}
    border={"1px"}
    borderColor={"rgba(255,255,255,1)"}
    m={5}
    size="lg"
    colorScheme="green"
    variant={"outline"}
    _hover={{ bg: "rgba(0,0,0,.5)" }}
    _active={{ bg: "rgba(0,0,0,1)" }}
    leftIcon={icon}
  >
    {children}
  </Button>
);

function HomeHeader() {
  return (
    <Flex
      w={"full"}
      h="calc(100vh - 80px)"
      maxH="calc(100vh - 80px)"
      my="auto"
      ratio={1}
    >
      <AspectRatio
        w="full"
        filter="auto"
        h="calc(100vh - 80px)"
        maxH="calc(100vh - 80px)"
        brightness="50%"
      >
        <video autoPlay loop muted>
          <source src={vid} type="video/mp4" preload="true" />
        </video>
      </AspectRatio>
      <Stack
        pos="absolute"
        w={"full"}
        justify={"center"}
        flexDirection={"column"}
        alignItems="center"
        textAlign="center"
        h="calc(100vh - 80px)"
        maxH="calc(100vh - 80px)"
      >
        <Container align={"center"}>
          <Image src={LOGO} alt="eco iris logo" maxW={"350px"} />
        </Container>
        <Heading size={"3xl"} color="#ffffff">
          <Highlight query="WRITE" styles={{ color: "rgba(0,150,255,1)" }}>
            พจญภัยในดินแดนแห่งความทรงจำ
          </Highlight>
        </Heading>
        <Text color="#ffffff">ดาวน์โหลด และลงทะเบียนได้แล้ววันนี้!</Text>
        <Wrap flexDirection={"row"} justify="space-around">
          <WrapItem>
            <Link href="download">
              <CustomButton href="download">DOWNLOAD</CustomButton>
            </Link>
          </WrapItem>
          <WrapItem>
            <Link href="https://eco-iris.com/member/">
              <CustomButton>REGISTER</CustomButton>
            </Link>
          </WrapItem>
          <WrapItem>
            <Link href="https://discord.gg/kwGcNTvaQG">
              <CustomButton icon={<FaDiscord />}>JOIN OUR DISCORD</CustomButton>
            </Link>
          </WrapItem>
        </Wrap>
        <Box bottom={1} pos={"absolute"}>
          <Lottie animationData={scrollDown} />
        </Box>
      </Stack>
    </Flex>
  );
}

export default HomeHeader;
