import React from "react";
import {
  Box,
  Center,
  useColorModeValue,
  Heading,
  Text,
  Stack,
  Image,
  Badge,
} from "@chakra-ui/react";

const IMAGE =
  "https://images.unsplash.com/photo-1518051870910-a46e30d9db16?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80";

export default function GuideItem({ type, title, image, date }) {
  function badgeColor(type) {
    let color;
    switch (type) {
      case "info":
        color = "yellow";
        break;
      case "event":
        color = "pink";
        break;
      case "update":
        color = "blue";
        break;
      default:
        color = "grey";
    }
    return color;
  }
  return (
    <Center py={12}>
      <Box
        role={"group"}
        p={6}
        maxW={"330px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.800")}
        // boxShadow={"2xl"}
        rounded={"lg"}
        pos={"relative"}
        zIndex={1}
      >
        <Box
          rounded={"lg"}
          mt={-12}
          pos={"relative"}
          height={"200px"}
          _after={{
            transition: "all .3s ease",
            content: '""',
            w: "full",
            h: "full",
            pos: "absolute",
            top: 5,
            left: 0,
            backgroundImage: `url(${image})`,
            filter: "blur(15px)",
            zIndex: -1,
          }}
          _groupHover={{
            _after: {
              filter: "blur(20px)",
            },
          }}
        >
          <Image
            rounded={"lg"}
            height={200}
            width={282}
            objectFit={"cover"}
            src={image}
          />
          <Heading
            fontSize={"xl"}
            fontFamily={"body"}
            fontWeight={500}
            textOverflow="ellipsis"
            overflow={"hidden"}
            whiteSpace={"nowrap"}
            overflowWrap={"break-word"}
            textAlign={"center"}
            textTransform={"uppercase"}
            textShadow={"#FFF 1px 0 3px"}
          >
            {title}
          </Heading>
        </Box>
      </Box>
    </Center>
  );
}
